import React from "react";

const Error404 = () => {
    return (
        <div>
            ERROR 404
        </div>
    )
}

export default Error404;